import React, { useContext } from "react";
import GridLine from "./GridLine";
import { WindowWidthContext } from "../hooks/window-width-context";
import { ReviewInViewContext } from "../hooks/review-in-view";


function Footer() {
  const windowWidth = useContext(WindowWidthContext);
  const reviewIsInView = useContext(ReviewInViewContext);

  const date = new Date();
  const currentYear = date.getFullYear();

  const showFooter = { display: "grid" };
  const hideFooter = { display: "none" };
  const footVisibility = reviewIsInView ? showFooter : hideFooter;

  //if the window with is less than 800px, return below
  if (windowWidth > 900) {
    return (
      <footer id="footer" style={footVisibility}>
        <GridLine />
        <div id="footnote">
          <p id="name">ZHIBANG JIANG</p>
          <p id="footerSlogan">Data-Powered Impacts</p>
        </div>
        <p id="copyright">Copyright ©️ {currentYear}</p>
      </footer>
    );
  } else {
    return (
      <footer id="footer" style={footVisibility}>
        <GridLine />
        <div id="footnote" className="footnoteSmall">
          <p id="name">ZHIBANG JIANG</p>
          {/* <p id="footerSlogan">Data-Powered Impacts</p> */}
          <p>Copyright ©️ {currentYear}</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
