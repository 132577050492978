import React from "react";

export function MethodChip(props) {
  return (
    <div className={`${props.layout}`}>
      <p className="subtitle">{props.subtitle}</p>
      <div className="methodContent">
        {props.eachMethod.map((item, index) => (
          <MethodElem key={index} content={item} />
        ))}
      </div>
    </div>
  );
}

export function MethodElem(props) {
  let bgColor;
  let textColor;
  let text = props.content;
  let [gray, brown, organge, yellow, green, blue, purple, pink, red] = ["rgb(55, 55, 55)", 'rgb(96, 59, 44)', 'rgb(133, 76, 29)', 'rgb(137, 99, 42)', 'rgb(43, 89, 63)', 'rgb(40, 69, 108)', 'rgb(73, 47, 100)', 'rgb(105, 49, 76)', 'rgb(110, 54, 48)'];
  switch (text) {
    // research
    case 'User Research':
    case 'Desk Research':
    case 'Stakeholder Workshops':
    case 'User Interviews':
    case 'Field Study':
    case 'Contextual Inquiry':
    case 'Competitive Analysis':
    case 'Co-Workshops':
    case 'K-J Method': bgColor = yellow; break;
    // synthesis
    case 'Journey Mapping':
    case 'User Modeling':
    case 'Task Modeling':
    case 'Scenario Swimlanes':
    case 'Storyboarding': bgColor = organge; break;
    // ideation
    case 'Prioritization':
    case 'Design Strategy': bgColor = red; break;
    // design topics
    case 'Design Principles':
    case 'Style Guide Creation':
    case 'Component Design':
    case 'Accessibility Guidelines':
    case 'Conversational Design':
    case 'Form Design':
    case 'Mobile':
    case 'Tablet':
    case 'Desktop':
    case 'Large Screen':
    case 'Design System':
    case 'Motion Design':
    case 'Illustration Design':
    case 'Syllabus Design':
    case 'Concept Design':
    case 'Business Intelligence':
    case 'Augmented Reality': bgColor = green; break;
    //evaluation
    case 'Heuristic Evaluation':
    case 'Survey':
    case 'Usability Testing': bgColor = blue; break;
    // datavis
    case 'Network Viz':
    case 'Geo Viz':
    case 'Pixel Viz':
    case 'Relational Viz':
    case 'Multiple Coordinated Views':
    case 'Visual Analytics': bgColor = purple; break;
    // dev
    case 'Documentation':
    case 'Flipped Classroom':
    case 'Tool Integration':
    case 'Exhibition':
    case 'Seminar':
    case 'API Management':
    case 'One-On-One Meeting':
    case 'Web Development': bgColor = pink; break;
  }
  return (
    <span className="methodItem" style={{ backgroundColor: bgColor, color: '#ffffff' }}>{text}</span>
  );
}