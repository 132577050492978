const reviews = [
  {
    key: 1,
    id: "shannon",
    nav: "Shannon Mattern, Professor",
    name: "Shannon Mattern, PhD",
    title:
      "Penn Presidential Compact Professor of Media Studies and Art History",
    org: "University of Pennsylvania",
    relationship:
      "Zhibang was instructed by Shannon when she taught in The New School’s School of Media Studies.",
    review:
      "I want to commend Zhibang for the extraordinary conscientiousness, care, openness to critique, and commitment to improvement.\\n\\In my 20 years of teaching, I don’t think I’ve ever encountered anyone as open to constructive critique — and as willing to allow others’ input to inform and improve their own work.",
  },
  {
    key: 2,
    id: "lee",
    nav: "Lee Kuczewski, CEO",
    name: "Lee Kuczewski",
    title: "Founder & CEO",
    org: "Merging Futures, LLC.",
    relationship: "Zhibang reported directly to Lee.",
    review:
      "As the CEO of a data and design consultancy with a background in data visualization, I've had the distinct privilege of working alongside Zhibang on a complex healthcare EHR product. His work ethic, attention to strategic detail, and empathy for the end user places him in the highest class of talent worldwide. His background as a trained UX/UI designer with a focus on data-driven insights will provide an extraordinary edge for your team.",
  },
  {
    key: 3,
    id: "sonya",
    nav: "Sonya Sun, Design Manager",
    name: "Sonya Sun",
    title: "Principal Design Manager",
    org: "Healthcare Department, Cloud and Smart Industries Business Group, Tencent",
    relationship: "Zhibang reported directly to Sonya.",
    review:
      "Zhibang can understand a new industry quickly, grasp the essence of a challenge, pinpoint problems, and analyze requirements of complex systems independently.\\n\\In the design of the Epidemiologic Survey Assistant, Zhibang learned from practice and refined his approach repeatedly. He always made sure the final output satisfied all parties. He can think outside the box to deliver better solutions with limited time and resources while keeping a high level of design quality.\\n\\In the standardized design of chart components, he built a high-quality chart design system, effectively improving the engineering level of the design team. The library of data charts he created fully reflected his competitive edge in data visualization. He received unanimous praise from all stakeholders.",
  },
  {
    key: 4,
    id: "mengqi",
    nav: "Mengqi Yang, Product Manager",
    name: "Mengqi Yang",
    title: "Senior Product Manager",
    org: "Healthcare Department, Cloud and Smart Industries Business Group, Tencent",
    relationship: "Zhibang collaborated with Mengqi.",
    review:
      "Zhibang is different from all other designers. With limited resources, even senior designers may finish their work with a perfunctory attitude, while he always brings something exceeding expectations to the project. His contribution to the Syndrome Monitoring product is irreplaceable, both in the past and future.",
  },
  {
    key: 5,
    id: "hao",
    nav: "Hao Chen, Associate Professor",
    name: "Hao Chen",
    title: "Associate Professor, IIB Awards Winner",
    org: "School of Design, Nanjing University of the Arts",
    relationship: "Zhibang was invited by Hao to host the workshop.",
    review:
      "Zhibang's workshop fully reflected his high level of professionalism in all aspects, including theoretical systems, subject settings, course structures, teaching, and guiding student assignments.\\n\\Students built a new understanding through his teaching and mastered interactive information design in just three weeks.",
  },
  // {
  //   key: 6,
  //   id: "jia",
  //   nav: "Jia Zeng, Design Manager",
  //   name: "Jia Zeng",
  //   title: "Design Manager",
  //   org: "Intelligent Platform Department, Cloud and Smart Industries Business Group, Tencent",
  //   relationship:
  //     "Zhibang reported directly to Jia in preparation for Tencent Design Week.",
  //   review:
  //     "I was the general manager of Tencent Design Week (TDW) 2021, and Zhibang was one of the volunteers I recruited for the planning team, serving as the head of the registration and check-in working group.\\n\\In several meetings, Zhibang stood out from the rest of the team. His ability to take the initiative, communicate, give feedback, and think out of the box is outstanding. Due to the uncertainty of the epidemic, it was tough for us to hold the TDW. Zhibang game-planned for every possible scenario and developed comprehensive plans through Service Design Thinking. I was impressed by the problem-solving ability and tendency to reflect and improve that Zhibang showed. He was totally my right-hand man.\\n\\In collaboration with other working groups, Zhibang also showed considerable maturity: he built up deep trust with his colleagues in the Gift Group; he was also able to express his views in a reasonable and justified manner in the conflicts with the Brand Design Group, maintaining a constructive, cooperative relationship while fighting for the rights and interests of his group. This is very rare.\\n\\The successful completion of the TDW is a credit to all the team members, and Zhibang played a vital role in it. It was a pleasure to work with Zhibang, and I would love to work with him again.",
  // },

  {
    key: 6,
    id: "jia",
    nav: "Jia Zeng, Design Manager",
    name: "Jia Zeng",
    title: "Design Manager",
    org: "Intelligent Platform Department, Cloud and Smart Industries Business Group, Tencent",
    relationship:
      "Zhibang reported directly to Jia in preparation for Tencent Design Week.",
    review:
      "Zhibang was one of the service design volunteers I recruited for the Tencent Design Week 2021, serving as the head of the registration and check-in group.\\n\\His ability to take the initiative, communicate, give feedback, and think out of the box is outstanding. Given the uncertainty of the epidemic, Zhibang game-planned for every possible scenario and developed comprehensive plans through Design Thinking. I was impressed by his problem-solving ability. He was totally my right-hand man.\\n\\In the cross-departmental collaboration, Zhibang also showed distinctive maturity: he built up deep trust with his colleagues; he also could express views in a reasonable and justified manner in the conflicts with other groups, maintaining a constructive and cooperative relationship while fighting for the rights of his group. This is very rare.\\n\\I can't wait to work with him again!",
  },
  {
    key: 7,
    id: "yi",
    nav: "Yi Liu, Design Manager",
    name: "Yi Liu",
    title: "Design Manager",
    org: "Big Data Design Center, Data Technology and Products Business Group, Alibaba Group",
    relationship:
      "Zhibang reported directly to Yi.",
    review:
      "I was initially interested in Zhibang's excellent visualization background. Then I found out that he could combine theory and practice well in his work and understand the current trends in the industry in both China and the West, which is very rare.\\n\\I still remember the cross-departmental seminar sesson he hosted on Intelligent BI. I still occasionally read and benefit from this presentation.",
  },
  {
    key: 8,
    id: "christine",
    nav: "Christine Yi, Group Head",
    name: "Christine Yi",
    title: "China Head",
    org: "Content Market Group, MediaCom, a GroupM Company",
    relationship:
      "Zhibang reported directly to Christine.",
    review:
      "What impressed me most about Zhibang is his professionalism in information visualization. MBA (MediaCom Beyond Advertising) Ocean Content, an online media resource database, is something I've always wanted to do since I led the team. Zhibang really made this happen. This is the most incredible achievement done by interns I've ever mentored. I believe that he can make a difference in any field with his personality.",
  },
];

export default reviews;
