import React, { useEffect, useContext, useState } from "react";
import { HomeInViewContext } from "../hooks/home-in-view";

export default function BackToTop() {
  const homeIsInView = useContext(HomeInViewContext);

  const [buttonVisible, setButtonVisible] = useState(false);

  useEffect(() => {
    if (!homeIsInView) {
      setButtonVisible(true);
    } else {
      setButtonVisible(false);
    }
  }, [homeIsInView]);

  const fadeInOutClass = buttonVisible ? "fade-in" : "fade-out";

  // after clicking the button, also setButtonVisible to false immediately

  useEffect(() => {
    const button = document.getElementById("back-to-top-button");

    button.addEventListener("click", () => {
      setButtonVisible(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  }, []);

  return (
    <button id="back-to-top-button" className={fadeInOutClass}>
      <img src="./assets/top.png" alt="back to top" />
    </button>
  );
}
