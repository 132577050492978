import React, { useEffect, useContext } from "react";
import GridLineHome from "./GridLineHome";
import Typewriter from "typewriter-effect";
import { Link } from "react-scroll";
import { WindowWidthContext } from "../hooks/window-width-context";

// function Type() {
//   const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

//   React.useEffect(() => {
//     const handleResize = () => setWindowWidth(window.innerWidth);
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);
//   if (windowWidth > 1439) {
//   return (
//     <React.Fragment>
//       <div>
//         Shaping Data-[
//         <Typewriter
//           options={{
//             strings: [
//               "Driven",
//               "Intensive",
//               "Empowered",
//               "Involved",
//               "Inclusive",
//               "Enabled",
//             ],
//             wrapperClassName: "dynamicText",
//             cursor: "·",
//             autoStart: true,
//             loop: true,
//             pauseFor: 2500,
//           }}
//         />]&nbsp;Impacts<br />for World-Class Orgranizations
//         <br />
//         Through Design
//       </div>
//     </React.Fragment>
//   );
// } else if (windowWidth < 1440 && windowWidth > 800) {
//   return (
//     <React.Fragment>
//       {/* 增加强制换行 */}
//       <div>
//         Shaping<br />Data-[
//         <Typewriter
//           options={{
//             strings: [
//               "Driven",
//               "Intensive",
//               "Empowered",
//               "Involved",
//               "Inclusive",
//               "Enabled",
//             ],
//             wrapperClassName: "dynamicText",
//             cursor: "·",
//             autoStart: true,
//             loop: true,
//             pauseFor: 2500,
//           }}
//         />]&nbsp;Impacts<br />for World-Class Orgranizations
//         <br />
//         Through Design
//       </div>
//     </React.Fragment>
//   );
// } else {
//   return (
//     <React.Fragment>
//       {/* 增加强制换行 */}
//       <div>
//         Shaping<br />Data-[
//         <Typewriter
//           options={{
//             strings: [
//               "Driven",
//               "Intensive",
//               "Empowered",
//               "Involved",
//               "Inclusive",
//               "Enabled",
//             ],
//             wrapperClassName: "dynamicText",
//             cursor: "·",
//             autoStart: true,
//             loop: true,
//             pauseFor: 2500,
//           }}
//         />]<br />Impacts<br />for World-Class<br />Orgranizations
//         <br />
//         Through Design
//       </div>
//     </React.Fragment>
//   );
//   }
// }


function Home() {

  const windowWidth = useContext(WindowWidthContext);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const normalVideo = <video id="covervideo" preload="auto" autoPlay muted loop poster="./assets/video_poster.png">
    <source src="./assets/cover_hd.webm" type="video/webm" />
    <source src="./assets/cover_hd.mp4" type="video/mp4" />
  </video>

  const coverVideoSafari =
    <video id="covervideo" preload="auto" muted autoPlay loop playsInline>
      <source src="./assets/cover_hd.mp4" type="video/mp4" />
    </video>

  const coverImgSafari = <img src="./assets/cover_hd.mp4" alt="cover" />;


  const safariCover = windowWidth < 900 ? coverImgSafari : coverVideoSafari
  const videoTag = isSafari ? safariCover : normalVideo;

  useEffect(() => {
    const videoElement = document.getElementById('covervideo');
    const triggerElement = document.getElementById('home');

    if (isSafari) {
      //based on two window width, set different video source
      if (windowWidth > 900) {
        if (videoElement) {
          if (triggerElement) {
            triggerElement.addEventListener('click', () => {
              videoElement.play();
            });
          }
        }
      }
      else {
        //do nothing
      }
    }
  }, [isSafari]);


  // useEffect(() => {
  //   if (isSafari) {
  //     if (video) {
  //       window.onload = function () {
  //         console.log("I love u")
  //         video.play();
  //       }
  //     }
  //   }
  // }, [isSafari]);


  {/* <div className="dynamicBox">
          <CornerSquare 
          position="absolute"
          left="0"
          top="0"
          right="auto"
          bottom="auto"
          />
          <CornerSquare style={{position: "absolute", left: 0, bottom: 0}}/>
          <CornerSquare style={{position: "absolute", right: 0, top: 0}}/>
          <CornerSquare style={{position: "absolute", right: 0, bottom: 0}}/>
        </div> */}

  if (windowWidth > 900) {
    return (
      <section id="home">
        <GridLineHome />
        <div id="home-video-wrapper">
          {videoTag}
        </div>
        <div id="titleBox">
          <h3>A Strategy-Driven Designer</h3>
          <h1 id="slogan">
              Shaping Data-Powered Impacts
              <br />for Leading Organizations
          </h1>
          <h4 id="business">by Designing Data, AI, Analytics Products</h4>
          <Link
            activeClass="active"
            to="work"
            spy={true}
            smooth={true}
            offset={8}
            duration={100}
          >
            <button id="CTA">Explore works</button>
          </Link>
        </div>
      </section>
    );
  } else {
    return (
      <section id="home">
        <GridLineHome />
        <div id="home-video-wrapper">
          {videoTag}
        </div>
        <div id="titleBox">
          <h3>Zhibang Jiang<br />A Strategy-Driven Designer</h3>
          <h1>
            <div id="slogan">
              Shaping<br />Data-Powered Impacts for Leading Organizations
            </div>
          </h1>
          <p id="business">by Designing Data, AI, Analytics Products</p>
          <Link
            activeClass="active"
            to="work"
            spy={true}
            smooth={true}
            offset={8}
            duration={100}
          >
            <button id="CTA">Explore works</button>
          </Link>
        </div>
      </section>
    );
  }


}

export default Home;
