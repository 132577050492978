let timer = Date.now();
let scrollHeight = 0;
let threshold = 1000;

const checkScroll = () => {
  if (scrollHeight === document.documentElement.scrollHeight && Date.now() - timer >= threshold) {
    var hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        //using scrollTo() to add a 96px offset to the top of the window
        window.scrollTo({
          top: element.offsetTop - 96,
          behavior: "smooth",
        });

        // element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      }
    }
    return;
  } else if (scrollHeight !== document.documentElement.scrollHeight) {
    timer = Date.now();
    scrollHeight = document.documentElement.scrollHeight;
  }

  requestAnimationFrame(checkScroll);
};

export const calculateScroll = (_threshold) => {
  threshold = _threshold;
  checkScroll();
};
