import React from 'react';
import Swiper from 'swiper';

const logoContainerSelector = '#logoContainer';
const projectSelector = '.projectWrap';

export const useNav = () => {
  const lockScroll = React.useRef(false);
  const lockScrollTimeout = React.useRef(null);
  const [activeElementIndex, setActiveElementIndex] = React.useState(-1);
  const [leftArrowDisabled, setLeftArrowDisabled] = React.useState(true);
  const [rightArrowDisabled, setRightArrowDisabled] = React.useState(false);

  const initIScroll = React.useCallback(() => {
    const updateArrowStatus = () => {
      setLeftArrowDisabled(window.swiper.isBeginning);
      setRightArrowDisabled(window.swiper.isEnd);
    };
    window.swiper = new Swiper(logoContainerSelector, {
      direction: 'horizontal',
      slidesPerView: 4,
      breakpoints: {
        10: {
          slidesPerView: 1,
        },
        801: {
          slidesPerView: 2,
        },
        1061: {
          slidesPerView: 4,
        },
        1441: { // 屏幕宽度大于1440px时显示5栏
          slidesPerView: 5,
        },
      },
      loop: false,
      updateOnWindowResize: true,
      allowTouchMove: false,
      on: {
        update: () => updateArrowStatus(),
        slideChange: () => updateArrowStatus(),
        resize: () => updateArrowStatus(),
      },
    });

    return () => window.swiper.destroy();
  }, []);
  
  const initPageScroll = React.useCallback(() => {
    const listener = () => {
      if (lockScroll.current) {
        return;
      }

      const elements = document.querySelectorAll(projectSelector);

      for (let i = elements.length - 1; i >= 0; i--) {
        const element = elements[i];
        const logoWrap = document.querySelector(`#link-${element.id}`);

        if (!logoWrap) {
          continue;
        }

        if (element.getBoundingClientRect().top - 109 <= 0) {
          setActiveElementIndex(i);
          const lastElementIndex = window.swiper.activeIndex + window.swiper.slidesPerViewDynamic() - 1;
          if (i < window.swiper.activeIndex) {
            window.swiper.slideTo(i);
          } else if (i > lastElementIndex) {
            window.swiper.slideTo(i - window.swiper.slidesPerViewDynamic() + 1);
          }
          return;
        }
      }

      setActiveElementIndex(-1);
    };

    // throttle
    setTimeout(() => {
      listener();
      window.addEventListener('scroll', listener);
      window.addEventListener('resize', listener);
    }, 300);

    return () => {
      window.removeEventListener('scroll', listener);
      window.removeEventListener('resize', listener);
    };
  }, []);

  const onElementClick = React.useCallback((org, index) => () => {
    setActiveElementIndex(index);

    const target = document.getElementById(org).getBoundingClientRect().top + document.documentElement.scrollTop - 109 + 2; // Plus 2 to clear float
    window.scrollTo({ left: 0, top: target, behavior: 'smooth' });
    lockScroll.current = true;
    clearTimeout(lockScrollTimeout.current);
    lockScrollTimeout.current = setTimeout(() => {
      lockScroll.current = false;
    }, 1000);
  }, []);

  React.useEffect(() => {
    const destroyIScroll = initIScroll();
    const destroyPageScroll = initPageScroll();

    return () => {
      destroyIScroll();
      destroyPageScroll();
    };
  }, [initIScroll, initPageScroll]);

  return {
    leftArrowDisabled,
    rightArrowDisabled,
    activeElementIndex,
    onElementClick,
    scrollLeft: () => window.swiper.slidePrev(),
    scrollRight: () => window.swiper.slideNext(),
  };
};
