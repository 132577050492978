import React, { useContext } from "react";
import { Link } from "react-scroll";
import { WindowWidthContext } from "../hooks/window-width-context";

function Header() {
  const windowWidth = useContext(WindowWidthContext);

  function toggleMenu() {
    const menu = document.getElementById("menu");
    const hamburger = document.getElementById("hamburger");
    // if the menu is open, close it, and vice versa
    if (menu.classList.contains("inactive")) {
      menu.classList.remove("inactive");
      menu.classList.add("active");
    }
    else {
      menu.classList.remove("active");
      menu.classList.add("inactive");
    } 
    // if the hamburger is open, close it, and vice versa
    if (hamburger.classList.contains("closed")) {
      hamburger.classList.remove("closed");
      hamburger.classList.add("open");
    }
    else {
      hamburger.classList.remove("open");
      hamburger.classList.add("closed");
    }

  }

  //define a const for the menu html
  const menu = <React.Fragment>
  <Link
    id="siteName"
    to="home"
    spy={true}
    smooth={true}
    offset={0}
    duration={200}
  >
    <h3>Zhibang Jiang</h3>
  </Link>
  <div id="pageNav">
    <Link
      activeClass="active"
      className="navButton"
      to="home"
      spy={true}
      smooth={true}
      offset={0}
      duration={200}
    >
      I AM HERE FOR
    </Link>
    <Link
      activeClass="active"
      className="navButton"
      to="work"
      spy={true}
      smooth={true}
      offset={8}
      duration={100}
    >
      WORKS
    </Link>
    {/* <Link
      activeClass="active"
      className="navButton"
      to="approach"
      spy={true}
      smooth={true}
      offset={0}
      duration={200}
    >
      APPROACH
    </Link> */}
    <Link
      activeClass="active"
      className="navButton"
      to="review"
      spy={true}
      smooth={true}
      offset={8}
      duration={200}
    >
      TESTIMONIALS
    </Link>
    <a className="hyperLink"
      href="https://www.linkedin.com/in/zhibangjiang"
      target="_blank"
      rel="noopener noreferrer"
    >
      FIND ME
    </a>
  </div></React.Fragment>

  //if the window is larger than 1366px, the heading will be displayed
  if (windowWidth > 1366) {
  return (
    <header id="sidebar">
    {menu}
    </header>
  );} else {
    //if the window is smaller than 1366px, the heading will be collapsed into a hamburger menu, and the menu will be displayed
    return (
      <header id="sidebar">
      {/* the menu is displayed when the hamburger icon is clicked */}
        <div id="hamburger" className="closed" onClick={toggleMenu}>ZJ
        </div>
        <div id="menu" className="inactive">
        {/* {menu} */}
        </div>
      </header>
    )
  }
}

export default Header;
