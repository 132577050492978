import React, { useState, useEffect } from "react";

export const HomeInViewContext = React.createContext();

export function HomeInViewProvider(props) {
  const [homeIsInView, setHomeIsInView] = useState(true);

  useEffect(() => {
    const homeElement = document.getElementById("home");

    // Create an intersection observer
    const observer = new IntersectionObserver((entries) => {
      let theState = entries[0].isIntersecting;
      setHomeIsInView(theState);
    });
    
    // Start observing the home element
    observer.observe(homeElement);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <HomeInViewContext.Provider value={homeIsInView}>
      {props.children}
    </HomeInViewContext.Provider>
  );
}