import React from "react";
import Zoom from '../external/react-medium-image-zoom';

const MediaChip = (props) => {
  const { fileName, type, poster, autoPlay } = props;
  const filePath = `img/${fileName}`;
  const posterPath = `img/${poster}`;

  const webMPath = `img/${fileName}.webm`;
  const mp4Path = `img/${fileName}.mp4`;

  const isAutoPlay =
    <video autoPlay muted loop width='100%'>
      {/* if the src of webMPath does not exist, then use the secondary src */}
      <source src={webMPath} type="video/webm" />
      <source src={mp4Path} type="video/mp4" />
    </video>;



  const notAutoPlay = <video controls poster={posterPath} width='100%'>
    <source src={webMPath} type="video/webm" />
    <source src={mp4Path} type="video/mp4" />
  </video>;

  const isAutoPlaySafari =
    <video preload="auto" autoPlay loop muted playsInline width='100%'>
      <source src={mp4Path} type="video/mp4" />
    </video>;

  const notAutoPlaySafari = <video playsInline controls poster={posterPath} width='100%'>
    <source src={mp4Path} type="video/mp4" />
  </video>;

  if (type === "video") {
    //tell if the brower is Safari
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      const videoTag = autoPlay ? isAutoPlaySafari : notAutoPlaySafari;
      return videoTag
    } else {
      const videoTag = autoPlay ? isAutoPlay : notAutoPlay;
      return videoTag
    }
  } else if (type === "image") {
    return <Zoom><img src={filePath} alt={fileName} width="100%"/></Zoom>
  } else {
    return <div>Unknown media type</div>;
  }
};

export default MediaChip;
