import React, { useState, useEffect } from "react";
import Header from "./Header";
import Home from "./Home";
import Work from "./Work";
import Approach from "./Approach";
import Footer from "./Footer";
import Review from "./Review";

function App() {
  return (
    <div id="canvas">
      <Header />
      <Home />
      <Work />
      <Review />
      <Footer />
    </div>
  );
}

export default App;