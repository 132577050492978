const projects = [
  {
    org: "tencent",
    list: [
      {
        key: 1,
        id: "syndrome",
        title: "Syndrome Monitoring and Warning",
        // proposition: "Monitering and prediting diseases for a metropolis",
        proposition:
          "I Design City-Wide 'Antivirus Software' to Safeguard Lives",
        duration: "2021-2022",
        client: "Shenzhen CDC",
        team: "<a href='https://www.tencent.com/en-us/business/smart-healthcare.html' target='_blank'>Tencent Heathcare</a>",
        role: "Lead UX/UI Designer",
        method: [
          "User Interviews",
          "User Modeling",
          "Task Modeling",
          "Prioritization",
          "Design Strategy",
          "Design System",
          "Desktop",
          "Visual Analytics",
        ],
        challenge:
          "<span class='subtitleNoPadding'>Challenge: </span>  The Shenzhen CDC was worn down by the campaigns to contain the outbreak of infectious diseases like COVID-19. It wished to prevent disease outbreaks through a predictive and proactive approach.",
        solution:
          "<span class='subtitleNoPadding'>Approach: </span> We used Healthcare AI to reveal spatial-temporal warning signs and drive decision intelligence. To achieve this goal, I enhanced human-centered AI. This included introducing Explainable AI to build human-computer trust, as well as human-in-the-loop methods to hedge against the limitations of machine intelligence.",
        result:
          "This has been launched for Shenzhen city, with a population of 18 million, to monitor 10k sensitive institutions and protect 400k sensitive people. This was incorporated as one of the best practices in China's first <a href='https://docs.qq.com/pdf/DSmVSRHhBeFd0b3Zu?' target='_blank'>Explainable AI Report</a>.",
        pics: [
          {
            fileName: "syndromecv.webp",
            type: "image",
          },
          {
            fileName: "syndrome1.webp",
            type: "image",
          },
          {
            fileName: "syndrome2.webp",
            type: "image",
          },
          {
            fileName: "syndrome3.webp",
            type: "image",
          },
        ],
      },
      {
        key: 2,
        id: "epid",
        title: "Epidemiologic Survey Assistant",
        // proposition: "I Make Everyone's Life Easier in the Pandemic Era",
        proposition:
          "I Modernize Legacy Workflows to Make Life Easier for Everyone",
        duration: "2021-2022",
        client: "Shenzhen CDC",
        team: "<a href='https://www.tencent.com/en-us/business/smart-healthcare.html' target='_blank'>Tencent Heathcare</a>",
        role: "Lead UX/UI Designer",
        method: [
          "Contextual Inquiry",
          "Scenario Swimlanes",
          "Journey Mapping",
          "Design Strategy",
          "Conversational Design",
          "Form Design",
          "Desktop",
          "Mobile",
          "Network Viz",
          "Geo Viz",
          "Usability Testing",
        ],
        challenge:
          "<span class='subtitleNoPadding'>Challenge: </span> China’s Zero-COVID policy required local governments to respond to positive cases immediately. They must trace the whereabouts of the cases over the prior two weeks and produce a report within 48 hours. However, the traditional workflow was no longer up to the task due to the massive repetitive dirty work it involved.",
        solution: `<span class='subtitleNoPadding'>Approach: </span> We fully optimized the situation using Conversational AI and Intuitive Data Entry. Additionally, we unwound the cable salad for their better cross-functional collaboration by bringing cross-platform applications and workflow automation, integrating isolated tasks that were scattered among multiple roles.`,
        result:
          "The system has been deployed in Shenzhen and Chengdu CDC, slashing the average survey time from 24 to 4 hours. This has been <a href='http://paper.people.com.cn/rmrbhwb/html/2022-04/19/content_25913573.htm' target='_blank'>featured in The People's Daily</a>, China's equivalent of The NY Times.",
        pics: [
          {
            fileName: "epidcv1.webp",
            type: "image",
          },
          {
            fileName: "epid2.webp",
            type: "image",
          },
          {
            fileName: "epidcv2.webp",
            type: "image",
          },
          {
            fileName: "epidcv3.webp",
            type: "image",
          },
        ],
      },
      {
        key: 3,
        id: "carecharts",
        title: "Data Visualization Design System",
        proposition: "I Promote Design Standardization to Raise R&D Efficiency",
        duration: "2021-2022",
        client: "Tencent Heathcare",
        team: "<a href='https://www.tencent.com/en-us/business/smart-healthcare.html' target='_blank'>Tencent Heathcare</a>",
        role: "Project Lead",
        method: [
          "User Research",
          "Competitive Analysis",
          "Design Principles",
          "Style Guide Creation",
          "Component Design",
          "Accessibility Guidelines",
          "Documentation",
          "Tool Integration",
        ],
        challenge:
        "<span class='subtitleNoPadding'>Challenge: </span>  Tencent Healthcare had extensive scenarios for medical data visualization, but the legacy data charts lacked consistency in interactions, layouts, colors, typography, and other design elements.",
       solution:
        "<span class='subtitleNoPadding'>Approach: </span> I created CareChart, a Figma-based Chart Design System, conceptualized holistically to ensure it's easy-to-use, maintainable, and scalable. It's tailored to be both user-friendly––aligning with human perception––and efficient for design and development.",

        result:
          "This was delivered as an internal-use design infrastructure. It was also integrated into our internal BI tool, allowing to generate charts in SVG format for precision design.",
        pics: [
          {
            fileName: "carechart1.webp",
            type: "image",
          },
          {
            fileName: "carechart2.webp",
            type: "image",
          },
          {
            fileName: "carechart3.webp",
            type: "image",
          },
        ],
      },
    ],
  },
  {
    org: "boeing",
    list: [
      {
        key: 4,
        id: "air",
        title: "Air Traffic Control System",
        proposition:
          "I Support Domain Experts in Eliminating Airspace Risks",
        duration: "2019",
        client: "Boeing China and CAAC",
        team: "<a href='http://vis.pku.edu.cn/' target='_blank'>The Vis Lab</a>, PKU",
        role: "Visualization Designer",
        method: [
          "User Interviews",
          "Desk Research",
          "Large Screen",
          "Visual Analytics",
        ],
        challenge:
          "<span class='subtitleNoPadding'>Challenge: </span> Boeing China and the Civil Aviation Administration of China (CAAC) found that the traditional Air Traffic Control system relied heavily on human participation, where human-caused safety risks might exist.",
        solution:
          "<span class='subtitleNoPadding'>Approach: </span> We designed a risk awareness system where we built analytics views of the airways, sectors, and ground airports, reflecting the global status of airspace. This enhanced the automation of identification of air traffic risks.",
        result:
          "This system has been successfully deployed at Beijing Capital International Airport and the CAAC North China Regional Administration.",
        pics: [
          {
            fileName: "boeingcv.webp",
            type: "image",
          },
          {
            fileName: "boeing1.webp",
            type: "image",
          },
          // {
          //   fileName: "boeing2.png",
          //   type: "image",
          // },
          {
            fileName: "boeing3.webp",
            type: "image",
          },
          {
            fileName: "boeing4.webp",
            type: "image",
          },
          {
            fileName: "boeing5.webp",
            type: "image",
          },
          {
            fileName: "boeing6.webp",
            type: "image",
          },
        ],
      },
    ],
  },
  {
    org: "nike",
    list: [
      {
        key: 5,
        id: "nike",
        title: "Nike Data Dashboard",
        proposition:
          "I Translate Dull Datasets into Engaging Visual Stories",
        duration: "2017",
        client: "Nike Beijing Strategy Team",
        team: "Nike Beijing Inc.",
        role: "Product Designer",
        method: [
          "Stakeholder Workshops",
          "Motion Design",
          "Illustration Design",
          "Tablet",
          "Geo Viz",
          "Heuristic Evaluation",
        ],
        challenge:
          "<span class='subtitleNoPadding'>Challenge: </span> Nike's global top executives were set to visit the Beijing branch for their annual report summit. The Beijing team was worried about their stale report format and wanted something more creative and engaging as a supplement for the executives to discover.",
        solution: `<span class='subtitleNoPadding'>Approach: </span> I designed a map-based dashboard that brought the data to life. Using a "one-shot" storytelling technique and seamless transitions, I transformed Nike's annual report from a bland Powerpoint-style narrative into a captivating "sightseeing" experience.`,
        result: `The client was thrilled with the result, and the dashboard raised the standard for presenting data at Nike Beijing.`,
        pics: [
          // {
          //   fileName: "nikecv.webp",
          //   type: "image",
          // },
          {
            fileName: "nike1",
            type: "video",
            autoPlay: true,
          },
          {
            fileName: "nike2",
            type: "video",
            autoPlay: true,
          },
          {
            fileName: "nike4",
            type: "video",
            autoPlay: true,
          },
          {
            fileName: "nike3",
            type: "video",
            autoPlay: true,
          },
        ],
      },
    ],
  },
  // {
  //   org: "wetland",
  //   list: [
  //     {
  //       key: 8,
  //       title: "Connected Specimens",
  //       proposition:
  //         "I keep an eye on the democratization and accessibility of novel data interactives",
  //       duration: "2021",
  //       client: "The National Wetland Museum of China",
  //       team: "Parsons School of Design",
  //       role: "Author",
  //       challenge:
  //         "The specimens nowadays on display in museums are human efforts to create traces of species that once existed, and, for the species that are still here, to resist their disappearance. But there is an experiential divide between the stories offered by museums and visitors’ desire to go further and between developed and less developed regions.",
  //       solution:
  //         "This project created a low-cost, data-driven framework that leveraged the scalability of digital media and the perceptibility of real-world objects to breathe new life into exhibits and enhance in-person engagement in museums.",
  //       result:
  //         "The project first focused on bird species at the National Wetland Museum of China, enhancing visitors' cognitive experience of the specimens through a digital-physical integration strategy. The future vision could be opened up to other species datasets and provid APIs and SDKs for further development.",
  //       pics: ["si1.gif", "si2.png", "si3.png", "si4.png"],
  //     },
  //   ],
  // },
  {
    org: "si",
    list: [
      {
        key: 8,
        id: "connectedbirds",
        title: "Avian Species Protection",
        proposition:
          "I Keep Eye on the Accessibility of Data-Driven Experiences",
        duration: "2020-2021",
        client: "Smithsonian Institution",
        team: "Parsons School of Design",
        role: "Author",
        method: [
          "Field Study",
          "Storyboarding",
          "Desktop",
          "Tablet",
          "Augmented Reality",
          "Relational Viz",
          "Geo Viz",
          "API Management",
          "Web Development",
        ],
        challenge:
          "The <i>Last Faces</i> and <i>Growing Whirlpool</i> showcases the work the Smithsonian does on conservation of avian species. It was extended to <i>A Symphony Beyond Reach</i> using sounds from extinct bird species to create a speculative biosphere of animals that no longer exist.",
        solution:
          "<i>Connected Birds</i> then elicited a flexible framework that breathed new life into static specimen exhibits in a data-driven way and enhanced in-person engagement for museums, especially in less-developed regions, thanks to its affordability and accessibility.",
        result:
          "The <i>Growing Whirlpool</i> was featured on the <a href='https://www.si.edu/openaccess/updates/parsons-visualizations' target='_blank'>Smithsonian’s website</a> as one of their top favorites. <i>Connected Birds</i> took the China Wetland Museum as a pilot scenario, demonstrating a framework that could be opened up to datasets of any species in any museum.",
        pics: [
          {
            fileName: "sicv.webp",
            type: "image",
          },
          {
            fileName: "si1",
            type: "video",
            autoPlay: true,
          },
          {
            fileName: "si2",
            type: "video",
            autoPlay: true,
          },
          {
            fileName: "si3.webp",
            type: "image",
          },
          {
            fileName: "si4",
            type: "video",
            poster: "si4poster.png",
            autoPlay: false,
          },
          {
            fileName: "si5",
            type: "video",
            autoPlay: true,
          },
          {
            fileName: "si6",
            type: "video",
            autoPlay: true,
          },
          {
            fileName: "si7",
            type: "video",
            autoPlay: true,
          },
        ],
      },
    ],
  },
  {
    org: "alibaba",
    list: [
      {
        key: 6,
        id: "smartcolorpicker",
        title: "Smart Color Picker",
        proposition: "I Simplify Tasks and Tailor Optimal Flows for Segmented Users",
        duration: "2020",
        client:
          "<a href='https://www.gartner.com/reviews/market/analytics-business-intelligence-platforms/vendor/alibaba-cloud/product/alibaba-quick-bi' target='_blank'>Quick BI</a> Users",
        team: "Alibaba Data Design Center",
        role: "UX & Data Designer",
        method: [
          "Desk Research",
          "Competitive Analysis",
          "Concept Design",
          "Business Intelligence",
          "Desktop",
        ],
        challenge:
          "<span class='subtitleNoPadding'>Challenge: </span> The Quick BI users wished to efficiently configure aesthetic and scientific color schemes for a single chart and make them consistent or consonant with the other charts on the dashboard.",
        solution:
          "<span class='subtitleNoPadding'>Approach: </span> I established color mapping rules to drive a smart color picker that helps people configure appropriate colors in a foolproof way.",
        result:
          "We collaborated with the tech team to build a designer-trained algorithm for color generation using artificial neural networks. We also prototyped color pickers to function as the algorithm’s interface.",
        pics: [
          {
            fileName: "alibaba2.png",
            type: "image",
          },
          {
            fileName: "alibaba3.png",
            type: "image",
          },
          {
            fileName: "alibaba4.png",
            type: "image",
          },
        ],
      },
    ],
  },
  {
    org: "pku",
    list: [
      {
        key: 7,
        id: "covid19",
        title: "The COVID-19 Barometer",
        proposition: "I Unlock the Value of Data for the Public Good",
        duration: "2020",
        client: "General Public",
        team: "<a href='http://vis.pku.edu.cn/' target='_blank'>The Vis Lab</a>, PKU",
        role: "Lead Visualization Designer",
        method: [
          "Mobile",
          "Pixel Viz",
          "Geo Viz",
          "Multiple Coordinated Views",
        ],
        challenge:
          "<span class='subtitleNoPadding'>Challenge: </span> At the beginning of the COVID-19 outbreak, the public found it challenging to learn more from the basic statistics displayed by the mainstream media in China. They were often unable to perceive national trends, cross-provincial differences, and future situations.",
        solution:
          "<span class='subtitleNoPadding'>Approach: </span> We published The COVID-19 Barometer, a color-coded pixel matrix, to accomplish the three above goals. In addition, we used advanced techniques to reduce biases and increase richness of the data.",
        result:
          "Our work informed more than 1M people in the first two months of the outbreak. The work has been featured by prestigious news media like <a href='https://medium.com/nightingale/lessons-from-chinas-epidemic-visualization-f3b25c136d51' target='_blank'>Nightingale</a> and <a href='https://www.informationisbeautifulawards.com/showcase/4664-the-covid-19-barometer' target='_blank'>longlisted in IIB Awards 2022</a>.",
        pics: [
          {
            fileName: "pkucv.webp",
            type: "image",
          },
          {
            fileName: "pku1",
            type: "video",
            autoPlay: true,
          },
          {
            fileName: "pku2.webp",
            type: "image",
          },
          {
            fileName: "pku3.webp",
            type: "image",
          },
          {
            fileName: "pku4.webp",
            type: "image",
          },
        ],
      },
    ],
  },
  {
    org: "nua",
    list: [
      {
        key: 9,
        id: "nua",
        title: "Instructed at the Top Design School in China",
        proposition:
          "I Popularize DataVis Knowledge by Teaching and Coaching",
        duration: "2021",
        client: "NUA Students",
        team: "NUA",
        role: "Instructor",
        method: [
          "Co-Workshops",
          "K-J Method",
          "Syllabus Design",
          "Seminar",
          "One-On-One Meeting",
          "Flipped Classroom",
          "Exhibition",
          "Survey",
        ],
        challenge:
          "<span class='subtitleNoPadding'>Challenge: </span> I was invited by Nanjing University of the Arts to teach graphic design students interactive visualizations based on their built infographics in 19 days. The students' knowledge of data literacy and interaction design was limited, posing a big challenge.",
        solution:
          "<span class='subtitleNoPadding'>Approach: </span> I introduced best practices and supported students in analyzing them collaboratively, synthesizing them into a design space, and internalizing the space as their own approaches. I acted as a designer of their learning journeys by hosting a syllabus wiki, instructing, coaching, co-organizing an on-site exhibition, and building <a href='http://nuaworkshop.oss-cn-shanghai.aliyuncs.com/' target='_blank'>a cyber show</a> to curate their works.",
        result:
          "All students delivered and curated quality interactive visualizations. In the anonymous teaching assessment, they submitted exceptionally strong evaluations. Three works have been longlisted in IIB Awards 2022, and one has been <a href='https://www.informationisbeautifulawards.com/showcase/5358-ancient-towers-in-china' target='_blank'>shortlisted</a>.",
        pics: [
          {
            fileName: "nua2.webp",
            type: "image",
          },
          {
            fileName: "nua3.webp",
            type: "image",
          },
          {
            fileName: "nua4",
            type: "video",
            poster: "nua4poster.png",
            autoPlay: false,
          },
          // {
          //   fileName: "https://nuashow.zhibang.design",
          //   type: "iframe",
          // },
          {
            fileName: "nuashow",
            type: "video",
            autoPlay: true,
          },
          {
            fileName: "nuatower",
            type: "video",
            autoPlay: true,
          },
        ],
      },
    ],
  },
];

export default projects;
