import React, { useContext } from "react";
import projects from "../projects";
import GridLine from "./GridLine";
import Logo from "./Logo";
import TextBox from "./TextBox";
import MediaChip from "./MediaBox";
import { HomeInViewContext } from "../hooks/home-in-view";


function Project(props) {
  return (
    <div id={props.org} className="projectWrap">
      {props.eachProject.map((item, index) => (
        <div key={index} className="projectItem">
            <TextBox
              id={item.id}
              title={item.title}
              proposition={item.proposition}
              challenge={item.challenge}
              solution={item.solution}
              duration={item.duration}
              client={item.client}
              team={item.team}
              role={item.role}
              method={item.method}
              result={item.result}
            />
          <div className="picBox">
            {/* <p className="timeLocation">2021-2022 @ Tencent Healthcare</p> */}
            {item.pics.map((media, index) => (
              <MediaChip
                key={index}
                fileName={media.fileName}
                type={media.type}
                poster={media.poster}
                autoPlay={media.autoPlay}
                loop={media.loop}
                muted={media.muted}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

function Work() {

  const homeIsInView = useContext(HomeInViewContext);

  const workHasBgColor = {
    backgroundColor: "var(--background-base)",
    transition: "background-color 0.2s ease-in",
    WebkitTransition: "background-color 0.2s ease-in",
    MozTransition: "background-color 0.2s ease-in",
  };

  const backgroundVisibility = homeIsInView ? undefined : workHasBgColor;

  return (
    <section id="work" style={backgroundVisibility} >
      <GridLine />
      <Logo />
      <div id="allProject">
        {projects.map((project, index) => (
          <Project key={index} org={project.org} eachProject={project.list} />
        ))}
      </div>
    </section>
  );
}

export default Work;