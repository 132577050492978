import React, { useState, useEffect } from "react";
import { MethodChip } from "./MethodElem";
import SplitType from 'split-type';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

function TextChip(props) {
  return (
    <div className={`${props.layout}`}>
      <p className="subtitle">{props.subtitle}</p>
      <p className="chipContent" dangerouslySetInnerHTML={{ __html: props.content }} />
    </div>
  );
}

function ParaText(props) {
  return (
    <React.Fragment>
      <p className="challenge" dangerouslySetInnerHTML={{ __html: props.challenge }} />
      <p className="solution" dangerouslySetInnerHTML={{ __html: props.solution }} />
    </React.Fragment>
  );
}

export default function TextBox(props) {

  const [mainTextHeight, setMainTextHeight] = useState(0);
  const [secTextHeight, setSecTextHeight] = useState(0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const mainText = document.getElementById(props.id + 'Main');
    const secText = document.getElementById(props.id + 'Sec');
    setMainTextHeight(mainText.offsetHeight);
    setSecTextHeight(secText.offsetHeight);
    // const secTextHeight = secText.offsetHeight;
  }, []);

  //update windowHeight when window is resized
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      // runSplit();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const spaceBetween = { height: 'calc(100vh - 109px)' };
  const fitContent = { height: 'fit-content' };
  const textBoxHeight = mainTextHeight + secTextHeight < windowHeight - 109 ? spaceBetween : fitContent;

  function runSplit() {
    const splitText = new SplitType(".proposition");

    function createScrollTrigger(triggerElement, timeline) {
        ScrollTrigger.create({
            trigger: triggerElement,
            toggleActions: "play none none none",
            start: "top 60%",
            onEnter: () => timeline.play()
        });
    }

    const lines = document.querySelectorAll(".line");

    lines.forEach(function (x, index) {
        let tl = gsap.timeline({ paused: true });
        tl.from(x.querySelectorAll(".char"), {
            opacity: 0.3,
            duration: 0.2,
            ease: "power1.out",
            stagger: { amount: 0.8 }
        });
        createScrollTrigger(x, tl);
    });
  }

  useEffect(() => {
    runSplit();
  },[]);

  return (
    <div className="textBox" style={textBoxHeight}>
      <div id={props.id + 'Main'} className="mainText">
        <h4 className="projTitle">{props.title}</h4>
        <h3 className="proposition">{props.proposition}</h3>
        <ParaText challenge={props.challenge} solution={props.solution} />
      </div>
      <div className="secondaryText" id={props.id + 'Sec'}>
        <TextChip layout="firstLeft" subtitle="Duration" content={props.duration} />
        {/* <TextChip layout="firstRight" subtitle="Designed For" content={props.client} />*/}
        {/* <TextChip layout="firstRight" subtitle="Team" content={props.team} /> */}
        <TextChip layout="firstRight" subtitle="Role" content={props.role} />
        <MethodChip layout="second" subtitle="Method" eachMethod={props.method} />
        <TextChip layout="third" subtitle="Impact" content={props.result} />
      </div>
      {/* <TextAnimation target=".proposition"/> */}
    </div>
  );
}