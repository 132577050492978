import React, { useContext, useEffect } from "react";
import { WindowWidthContext } from "../hooks/window-width-context";

function GridLine() {

  const windowWidth = useContext(WindowWidthContext);

  if (windowWidth > 1439) {
    return (
      // <div id="gridLine"></div>
      <React.Fragment>
        <div id="lineFirst" className="edgeLine"></div>
        <div id="lineSecond" className="gridLine"></div>
        <div id="lineThird" className="gridLine" ></div>
        <div id="lineFourth" className="gridLine"></div>
        <div id="lineFifth" className="gridLine" ></div>
        <div id="lineSixth" className="edgeLine"></div>
      </React.Fragment>
    );
  } else if (windowWidth < 1440 && windowWidth > 1059) {
    return (
      <React.Fragment>
        <div id="lineFirst" className="edgeLine"></div>
        <div id="lineSecond" className="gridLine" ></div>
        <div id="lineThird" className="gridLine"></div>
        <div id="lineFourth" className="gridLine"></div>
        <div id="lineFifth" className="edgeLine"></div>
      </React.Fragment>
    );
  } else if (windowWidth < 1060 && windowWidth > 900) {
    return (
      <React.Fragment>
        <div id="lineFirst" className="edgeLine"></div>
        <div id="lineSecond" className="gridLine" ></div>
        <div id="lineThird" className="edgeLine"></div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div id="lineFirst" className="edgeLine"></div>
        <div id="lineSecond" className="edgeLine" ></div>
      </React.Fragment>
    );
  }
}

export default GridLine;
