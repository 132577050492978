import React, { useState } from "react";
import { Link } from "react-scroll";

function Content(props) {
  return (<div id={props.id} className="p">{props.content}</div>)
}

function Identity(props) {
  let img;
  try {
    img = require (`../../public/photo/${props.id}.png`);
    return (
      <div id={`identity${props.id}`} className="identityItem">
        <img src={img} alt="" />
        <p className="name">{props.name}</p>
        <p className="title">{props.title}</p>
        <p className="org">{props.org}</p>
        <p className="relationship">{props.rela}</p>
      </div>
    )
  } catch (error) {
    return (
      <div id={`identity${props.id}`} className="identityItem">
        <p className="name">{props.name}</p>
        <p className="title">{props.title}</p>
        <p className="org">{props.org}</p>
        <p className="relationship">{props.rela}</p>
      </div>
    )
  }
}

function NavTree(props) {
  
    function handleSetActive() {
      const activeName = props.id
      document.getElementById("identity" + activeName).classList.add('isActive');
    }
  
    function handleSetInactive() {
      const inactiveName = props.id
      document.getElementById("identity" + inactiveName).classList.remove('isActive');
    }
    
    return (
      <Link
        className="navLink"
        activeClass="selected"
        to={props.id}
        spy={true}
        smooth={true}
        offset={-96}
        duration={200}
        onSetActive={handleSetActive}
        onSetInactive={handleSetInactive}
      >
        {props.nav}
      </Link>
    )
    
  }

  export {Content, NavTree, Identity};