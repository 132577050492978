import React, { useState, useEffect } from "react";

// 创建一个Context对象
export const WindowWidthContext = React.createContext();

export function WindowWidthProvider(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <WindowWidthContext.Provider value={windowWidth}>
        {props.children}
    </WindowWidthContext.Provider>
  );
}
