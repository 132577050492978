import React, { useState, useEffect } from "react";

export const ReviewInViewContext = React.createContext();

export function ReviewInViewProvider(props) {
  const [reviewIsInView, setReviewIsInView] = useState(true);

  useEffect(() => {
    const reviewElement = document.getElementById("review");

    // Create an intersection observer
    const observer = new IntersectionObserver((entries) => {
      let theState = entries[0].isIntersecting;
      setReviewIsInView(theState);
    });
    
    // Start observing the home element
    observer.observe(reviewElement);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <ReviewInViewContext.Provider value={reviewIsInView}>
      {props.children}
    </ReviewInViewContext.Provider>
  );
}