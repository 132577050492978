import React, { useContext, useEffect } from "react";
import { WindowWidthContext } from "../hooks/window-width-context";

export default function GridLineHome() {

  const windowWidth = useContext(WindowWidthContext);

  const [first, second, third, fourth, fifth, last] = 
  ["rgba(0, 0, 0, 0.75)", 
  "rgba(0, 0, 0, 0.61)", 
  "rgba(0, 0, 0, 0.46)", 
  "rgba(0, 0, 0, 0.32)", 
  "rgba(0, 0, 0, 0.18)",
  "rgba(0, 0, 0, 0.10)"];

  const firstColor = { backgroundColor: first, zIndex: "0 !important"};
  const secondColor = { backgroundColor: second, zIndex: "0 !important"};
  const thirdColor = { backgroundColor: third, zIndex: "0 !important"};
  const fourthColor = { backgroundColor: fourth, zIndex: "0 !important"};
  const fifthColor = { backgroundColor: fifth, zIndex: "0 !important"};
  const lastColor = { backgroundColor: last, zIndex: "0 !important"};

  if (windowWidth > 1439) {
    return (
      // <div id="gridLine"></div>
      <React.Fragment>
        <div id="lineFirst" className="edgeLine"></div>
        <div id="lineSecond" className="gridLine" style={firstColor}></div>
        <div id="lineThird" className="gridLine" style={secondColor}></div>
        <div id="lineFourth" className="gridLine" style={thirdColor}></div>
        <div id="lineFifth" className="gridLine" style={fourthColor}></div>
        <div id="lineSixth" className="edgeLine" style={fifthColor}></div>
        <div id="lineLast" className="impLine" style={lastColor}></div>
      </React.Fragment>
    );
  } else if (windowWidth < 1440 && windowWidth > 1059) {
    return (
      <React.Fragment>
        <div id="lineFirst" className="edgeLine" ></div>
        <div id="lineSecond" className="gridLine" style={secondColor}></div>
        <div id="lineThird" className="gridLine"style={thirdColor}></div>
        <div id="lineFourth" className="gridLine"style={fourthColor}></div>
        <div id="lineFifth" className="edgeLine" style={fifthColor}></div>
        <div id="lineSixth" className="impLine" style={lastColor}></div>
      </React.Fragment>
    );
  } else if (windowWidth < 1060 && windowWidth > 900) {
    return (
      <React.Fragment>
        <div id="lineFirst" className="edgeLine" ></div>
        <div id="lineSecond" className="gridLine" style={secondColor}></div>
        <div id="lineThird" className="edgeLine" style={thirdColor}></div>
        <div id="lineFourth" className="impLine"style={fourthColor}></div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div id="lineFirst" className="edgeLine" style={fourthColor}></div>
        <div id="lineSecond" className="edgeLine" style={fourthColor}></div>
        <div id="lineThird" className="impLine" style={fourthColor}></div>
      </React.Fragment>
    );
  }
}
