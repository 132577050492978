import React, { useState,useContext } from "react";
import GridLine from "./GridLine";
import { Link } from "react-scroll";
import reviews from "../reviews";
import { NavTree, Content, Identity } from "./ReviewElement";
import BackToTop from "./BackToTop";
import { WindowWidthContext } from "../hooks/window-width-context";

export default function Review() {
  const windowWidth = useContext(WindowWidthContext);
  const bigTitle = <h2 id="reviewTitle">is Widely Recognized for His Professionalism and Work Ethics</h2>
  const tinyTitle = <h3 id="reviewTitle">Zhibang is Widely Recognized for His Professionalism and Work Ethics</h3>

  const responsiveTitle = windowWidth > 900 ? bigTitle : tinyTitle;

  return (
    <section id="review">
      <BackToTop />
      <GridLine />
      {responsiveTitle}
      <div id="navTree">
        {reviews.map(eachReview =>
          <NavTree
            key={eachReview.key}
            id={eachReview.id}
            nav={eachReview.nav}
          />
        )}
      </div>
      <div id="content">
        {reviews.map(eachReview =>
          <Content
            key={eachReview.key}
            id={eachReview.id}
            // creating a new line below
            content={eachReview.review.split('\\n\\').map((content, index) =>
              <div style={{ marginBottom: "8px" }} key={`${content}_${index}`}>{content}</div>)} />
        )}
      </div>
      <div id="identity">
        {reviews.map(eachReview =>
          <Identity
            key={eachReview.key}
            id={eachReview.id}
            name={eachReview.name}
            title={eachReview.title}
            org={eachReview.org}
            rela={eachReview.relationship}
          />
        )}
      </div>
    </section>
  );
}