import React, { useContext }  from "react";
import projects from "../projects";
import GridLineLogo from "./GridLineLogo";
import SVGInject from "@iconfu/svg-inject";
import { useNav } from "../hooks/use-nav";
import { HomeInViewContext } from "../hooks/home-in-view";

function Logo() {
  const homeIsInView = useContext(HomeInViewContext);

  const {
    leftArrowDisabled,
    rightArrowDisabled,
    onElementClick,
    scrollLeft,
    scrollRight,
    activeElementIndex,
  } = useNav();

  React.useEffect(() => {
    const logoView = document.getElementById("logoView");
    
    if (homeIsInView) {
      logoView.classList.remove("isOnTop");
    } else {
      logoView.classList.add("isOnTop");
    }
  }, [homeIsInView]);

  return (
    <div id="logoView">      
      <button
        id="leftArrow"
        className="arrow"
        disabled={leftArrowDisabled}
        onClick={scrollLeft}
      >
        <img src="./assets/left.png" alt="left arrow" />
      </button>
      <button
        id="rightArrow"
        className="arrow"
        disabled={rightArrowDisabled}
        onClick={scrollRight}
      >
        <img src={"./assets/left.png"} alt="right arrow" />
      </button>
      <div id="logoContainer">
        <div id="logoNav" className="swiper-wrapper">
          {projects.map((project, index) => (
            <SVGLogo
              key={index}
              org={project.org}
              onClick={onElementClick(project.org, index)}
              active={activeElementIndex === index}
            />
          ))}
        </div>
      </div>
      <GridLineLogo />
    </div>
  );
}

function SVGLogo(props) {
  return (
    <div
      id={`link-${props.org}`}
      className={`logoWrap swiper-slide ${props.active ? "active" : ""}`}
      onClick={props.onClick}
    >
      <img
        className="orgLogo"
        src={"logo/" + props.org + ".svg"}
        onLoad={(e) => SVGInject(e.target)}
        alt={`${props.org} Logo`}
      />
    </div>
  );
}

export default Logo;
