import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { WindowWidthProvider } from "./hooks/window-width-context";
import { HomeInViewProvider } from "./hooks/home-in-view";
import { ReviewInViewProvider } from "./hooks/review-in-view";
import { calculateScroll } from "./initial-scroll";

import "./styles/zoomstyle.css";
import "./styles/layout.scss";
import "./styles/styles.scss";
import "./styles/type.scss";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

calculateScroll(1000);

root.render(
  <WindowWidthProvider>
    <HomeInViewProvider>
      <ReviewInViewProvider>
        <App />
      </ReviewInViewProvider>
    </HomeInViewProvider>
  </WindowWidthProvider>
);
